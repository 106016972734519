/**
 * @enum {string}
 */
export const FeatureFlags = {
  SLIDE_SEARCH: "SLIDE_SEARCH",
  HISTOLOGY_DASHBOARD: "HISTOLOGY_DASHBOARD",
  ROI_SEARCH: "ROI_SEARCH",
  HISTOLOGY_FILTERS_UPDATE: "HISTOLOGY_FILTERS_UPDATE",
  GLOBAL_FILTERS_UPDATE: "GLOBAL_FILTERS_UPDATE",
};

/**
 * Checks if a feature flag is enabled.
 * @param {keyof typeof FeatureFlags} flagName - The name of the feature flag.
 * @returns {boolean} - Returns true if the feature flag is enabled, otherwise false.
 */
export function isFeatureEnabled(flagName) {
  if (
    typeof flagName !== "string" ||
    !(flagName.toUpperCase() in FeatureFlags)
  ) {
    throw new TypeError(`Invalid flagName: ${flagName}`);
  }

  const envVarName = `VUE_APP_FEATURE_${flagName.toUpperCase()}`;
  const flagValue = config[envVarName] || process.env[envVarName];

  return flagValue === "true" || flagValue === "1";
}
